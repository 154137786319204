// Captions

.wp-caption-text {
  @include remy(11);
  color: $text-color; //#fff;
  padding: 10px 10% 10px 0;
  background: transparent;
  transition: opacity .3s ease;
  z-index: 1;
  text-align: left;

  .marker__img-wrap & {
    padding: 10px 20px;
  }

  .info-overlay__img-wrap & {
    padding: 10px 0;
  }

  p {
    line-height: 14px;
    margin: 0;
    z-index: 2;
  }

  @include bp(mobile) {
    padding: 10px 0;

    .article-event &,
    .type-process & {
      color: #fff;
      padding: 10px 20px;
    }
  }


}

figure {
  position: relative;

  .content-wrap-page & {
    margin-top: 80px;
    margin-bottom: 80px;
    margin-left: 40px;
  }

  .card & {
    margin: 0;
    height: 100%;
  }

  &:hover {
    .wp-caption-text,
    .img-caption {
      opacity: 1;
    }
  }
}

img.alignnone.size-full {
  .content-wrap-page & {
    margin-top: 80px;
    margin-bottom: 80px;
    margin-left: 0;
  }
}

@include bp(mobile) {
  figure {
    margin: 30px 0;
  }
}
