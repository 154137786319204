.single__header {
  position: relative;
  height: auto;
  background-color: $bg-gray;

  .single-cannery & {
    background-color: $cannery-bg;
  }

  .single-event & {
    background-color: $timeline-bg;
  }

  .single-process & {
    background-color: $process-bg;
  }

  h1 {
    margin-top: 10px;
    color: $nav-color;
  }

  .hero-content-abs {
    position: relative;
    padding: 300px 40px 100px;
    left: auto;
    transform: none;
  }

}

.hero--single {
  height: 50vh;
  min-height: 500px;
}

.single__breadcrumb {

  li {
    display: inline-block;
    color: $nav-color;

    a {
      color: $nav-color;

      &:hover {
        border-color: $nav-color;
      }
    }
  }
}

.single__feature-image {
  width: 100%;
  margin-top: -80px;
  overflow: hidden;

  img {
    width: 100%;
    object-position: center;
    object-fit: cover;
    max-height: 800px;
  }
}

.single__share {
  position: absolute;
  right: 40px;
  top: 0;

  h2 {
    @include remy(16);
    line-height: 1.4;
  }
}

.single__main {
  max-width: 571px;
}

.single-article__footer {
  padding-top: 50px;
  margin-top: 180px;
  margin-bottom: 20px;

  h2 {
    @include headings();
  }

  .card--sm {
    height: 291px;

    .card__img {
      height: 291px;
    }
  }

  .card__icon {
    bottom: 20px;
    top: auto;
  }

  .card-link {
    padding-left: 50px;
  }

  .card__desc-l {
    max-width: 100%;
  }

  .card__desc-rel {
    padding-bottom: 0;
    padding-left: 20px;
    padding-top: 20px;

    h3 {
      margin-top: 0;
      font-weight: bold;
      @include remy(18);
      display: inline-block;
      border-bottom: 1px solid transparent;
      transition: all .2s ease-out;
    }
  }

  a {
    &:hover {
      h3 {
        border-bottom: 1px solid $text-color;
      }
    }
  }

}

// Single rows

.single-row {
  margin: 50px auto;
  max-width: 571px;
}

.single-row-vid,
.single-row-img {
  max-width: 100%;

  img {
    width: 100%;
  }

}

.img-caption {
  @include remy(11);
  color: $text-color;
  padding: 10px 10% 10px 0;
  background: transparent;
  transition: opacity .3s ease;
  z-index: 1;
  text-align: left;

  p {
    line-height: 16px;
    margin: 0;
    z-index: 2;
  }

  @include bp(mobile) {
    padding: 10px 0;
  }

}


.single figure {
  position: relative;
  margin: 0 auto;

  &:hover {
    .img-caption {
      opacity: 1;
    }
  }
}


.audio-caption,
.video-caption {
  @include remy(14);
  font-weight: bold;
  display: block;
}

a.video-caption,
a.audio-caption {
  color: $blue;
  font-weight: bold;
  @include remy(14);
  position: relative;
  width: 100%;
  text-align: right;
  margin-top: 20px;

  img {
    width: 24px;
    display: inline-block;
    margin-right: 10px;
  }

  &:hover {
    border-bottom: 1px solid transparent;
    color: $text-color;
  }

}

.single-row-headline-text,
.single-row-headline {
  max-width: 60%;
  padding-left: 100px;
}

.single-row-img-text__text {
  align-self: center;
}

.single-row-audio {
  width: 100%;
  max-width: 600px;
}

.single-row__audio {
  width: 100%;
}

.single-row__video {
  width: 100%;
  overflow-y: auto;
}

// Canneries Single

.cannery-excerpt {
  @include remy(20);
  line-height: 30px;
  margin-bottom: 40px;
}

//mobile

@include bp(mobile) {
  .single-article__footer {
    margin-top: 0;

    .u-mt50 {
      margin-top: 0;
    }

    .card__desc-rel {
      padding-bottom: 20px;
    }
  }

  .single__share {
    position: relative;
    right: auto;
    width: 100%;
    padding: 20px 0 0;
    text-align: right;
  }
}
