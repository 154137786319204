a.skip-main {
  @include remy(14);
  font-weight: bold;
  padding: 10px 20px;
  color: $nav-color;
  background-color: $text-color;
  position: absolute;
  left: 5px;
  top: 5px;
  width: 200px;
  max-width: 100% !important;
  height: auto;
  z-index: 999;
  opacity: 0;
}

a.skip-main:focus {
  opacity: 1;
}

.header--fixed {
  position: absolute;
  z-index: $header-index;
  width: 100%;
  top: 0;
  left: 0;
  height: $header-height;
}

.header--mobile {
  position: absolute;
  z-index: $header-index;
  width: 100%;
  top: 0;
  left: 0;
  height: $header-height;

  @include bp(mobile) {
    .no-js & {
      position: relative;
      width: 100%;
      top: auto;
      left: auto;
      height: auto;
    }
  }

}

.header--sticky {
  position: fixed;
  z-index: $sticky-header-index;
  width: 100%;
  top: 0;
  left: 0;
  height: $header-height;
}

#sticky-header {
  display: none;
}


.header__top {
  float: right;
  padding-top: 10px;
  padding-bottom: 0;

  @include bp(mobile) {
    padding: 0;
    display: block;
    position: absolute;
    top: 10px;
    right: 20px;

    .nav-secondary__nav {
      margin-top: 0;

      li {
        margin-left: 0;
      }
    }

    img {
      max-width: 80px;
    }
  }
}

.header__bottom {
  width: 100%;
  align-items: center;
  justify-content: space-between;

  @include bp(mobile) {
    padding: 20px;
    display: flex;

    .no-js & {
      display: block;
    }
  }
}

.brand {
  width: 100px;
  z-index: 999;
  margin-right: 40px;
  display: block;

  a {
    width: 100px;
  }
}

.brand__logo {
  width: 100px;
  position: relative;
  z-index: $header-index;

  @include bp(mobile) {
    .no-js & {
      content: "";
    }
  }
}

html:lang(en) {
  .brand__logo {
    content: url("../images/tides-to-tins-logo-reverse.svg");
  }
}

html:lang(fr-CA) {
  .brand__logo {
    content: url("../images/tides-to-tins-logo-reverse-fr.svg");
  }
}

.index,
.french-index,
.home {

  .header__bottom {
    padding-left: 0;

    @include bp(mobile) {
      padding-left: 20px;
    }
  }

  .brand {
    width: 154px;

    a {
      width: 154px;
      display: block;
      border-bottom: 0;
    }

    @media screen and (max-width: 1150px) {
      width: 100px;

      a {
        width: 100px;
      }
    }

  }

  .brand__logo {
    width: 154px;

    @media screen and (max-width: 1150px) {
      width: 100px;

      a {
        width: 100px;
      }
    }

    @include bp(mobile) {
      width: 100px;
      padding: 0;

      a {
        width: 100px;
      }
    }

  }
}

.header-offset {
  padding-top: $header-height;
  min-height: 468px;

  @include bp(mobile) {
    min-height: 0;
  }
}

.header--sticky {
  .brand {
    a {
      width: 80px;
    }
  }
}

//
// header shrinking down

header.is-shrank {
  // same height as child element. this prevent header from covering sticky anchor below
  height: 90px;
  background-color: #4f4f4f;
  background-color: rgba(#4f4f4f, .9);
  box-shadow: 0 2px 6px rgba(0, 0, 0, .2);
  z-index: $sticky-header-index;

  .menu-toggle {
    padding: 20px;
    margin-top: 10px;
  }

  .header__bottom {
    padding: 5px 0;

    @include bp(mobile) {
      padding: 5px 20px;
    }
  }

  .nav-secondary {
    display: none;
  }

  .flex-grid {
    align-items: center;
    justify-content: space-between;
  }

  .brand {
    width: 80px;
  }

  .brand__logo {
    width: 80px;

    @include bp(mobile) {
      width: 73px;
    }
  }

  @include bp(mobile) {
    height: 83px;
  }
}

