// Grid system

// margins and sizings
$mobile-margin: 20px;
$desktop-margin: 40px;

$gutter-size: 32px;

.container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

.container--inner {
  @include bp(desktop) {
    // desktop content width + 25x2 margin to prevent elements from touching the edge
    max-width: calc(#{$desktop-content-width} + #{$desktop-margin * 2});
    padding: 0 $desktop-margin;
  }

  @include bp(tablet) {
    padding: 0 $desktop-margin;
  }

  @include bp(mobile) {
    padding: 0 $mobile-margin;

    .header & {
      padding: 0;
    }
  }
}

.container--full {
  // width: 100vw; this causes horizontal scroll bar on Edge
  width: 100%;
}

.content-wrap-half {
  width: 50%;

  @include bp(mobile) {
    width: 100%;
  }

}

.page-header {
  .content-wrap {
    max-width: 472px;
  }
}

// Flexbox based grid

.flex-grid {
  display: flex;
  flex-flow: row nowrap;
}

.flex-grid-wrap {
  display: flex;
  flex-flow: row wrap;
}

.flex-grid--fluid-gutter {
  justify-content: space-between;
}

.flex-grid--fixed-gutter {
  margin-right: $gutter-size * -1;

  .col {
    padding-right: $gutter-size;
  }

  .col40 {
    width: 40%;
  }

  .col30 {
    width: 30%;
  }

  .col60 {
    width: 60%;
  }
}

.col {
  width: 100%;
}

.col30 {
  width: 30%;
}

.col40 {
  width: 40%;
}

.col45 {
  width: 45%;
}

.col49 {
  width: 49%;
}

.col50 {
  width: 50%;
}

.col60 {
  width: 60%;
}

.col70 {
  width: 70%;
}

.flex-grid--half {
  flex-wrap: wrap;

  .col {
    width: 50%;

    .home & {
      width: 49%;

      @include bp(mobile) {
        width: 100%;
      }
    }

    @include bp(mobile) {
      width: 100%;
    }
  }
}

@include bp(mobile) {
  .flex-grid--half,
  .flex-grid,
  .flext-grid--fluid-gutter {
    display: block;

    .map-slider-wrap & {
      display: flex;
    }

    .col {
      width: 100%;
      padding-right: 0;
    }

  }

  .col50,
  .col40,
  .col30,
  .col45,
  .col49,
  .col60,
  .col70 {
    width: 100%;
  }

  .nav-mobile .col50 {
    width: 50%;
  }

}



