body {
  color: $text-color;
  font-family: $font-stack;
}

a {
  color: $link-color;
  transition: all .2s ease-out;
  border-bottom: 1px solid transparent;

  img {
    transition: opacity .2s ease-out;
  }

  &:hover {
    border-bottom: 1px solid $text-color;

    img {
      opacity: .9;

      &.card__icon {
        opacity: 1;
      }
    }
  }

  &.brand,
  &.card,
  &.download-item__link {
    &:hover {
      border-bottom: 1px solid transparent;
    }
  }

  .card & {
    &:hover {
      border-bottom: 1px solid transparent;
    }
  }

  &.download-item__link {
    &:hover {
      border: 2px solid $text-color;
    }
  }

}

.content-wrap p a {
  border-bottom: 1px solid $text-color;

  &:hover {
    border-bottom: 1px solid transparent;
  }
}

p {
  line-height: 24px;

  &.small {
    @include remy(12);
    margin-top: 20px;
    line-height: 18px;
  }
}

h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

h1 {
  @include remy(58);
  line-height: 62px;
  letter-spacing: -.86px;
  font-family: $font-stack-headings;

  .content-wrap-page & {
    @include remy(22);
    line-height: 29px;
    max-width: 384px;
    margin-top: 80px;
    margin-bottom: 40px;
  }

  @include bp(mobile-tablet) {
    padding-right: 20px;
    margin-top: 15px;
    @include remy(42);
    line-height: 46px;
  }
}

.glossary {
  h2 {
    margin-top: 40px;
  }
}

dl {
  dt {
    font-weight: bold;
  }

  dd {
    margin-left: 0;
    margin-bottom: 20px;
  }
}

//Animations

.anim {
  transition-property: all;
  transition-timing-function: ease-out;
  transition-duration: .4s;
  opacity: 1;

  &.go {
    opacity: 1;
  }

  @media (hover:none) {
    opacity: 1;
  }
}



// * * * * *
// Homepage (Language select)

.ml-wrap {
  min-height: 100vh;
  padding: 50px 0;
}

.ml-content {
  max-width: 800px;
  margin: 0 auto;
}

.ml-header {
  text-align: center;
}

.ml-logo {
  width: 170px;
  display: inline-block;
  margin-bottom: 30px;
}

.ml-headlines {
  margin: 25px 0 45px;

  h2 {
    margin: 0;
  }
}

.ml-cta {
  background-color: #fff;
  // border-radius: 5px;
  overflow: hidden;
  padding-bottom: 30px;
}

.ml-cta-main {
  padding-right: 25px;
  padding-left: 25px;

  @include bp(mobile) {
    padding-left: 0;
    padding-right: 0;
  }

}

.ml-text-fields {
  text-align: left;
  padding: 0;

  h2 {
    font-family: $font-stack-headings;
    font-weight: 400;
    @include remy(20);
    margin-top: 40px;
    margin-bottom: 30px;
    text-transform: none;
    word-wrap: normal;
  }

  .col {
    width: 50%;
    padding: 5px 25px 0;
    display: flex;
    flex-wrap: wrap;
  }

  @include bp(mobile) {
    padding: 0;
    display: flex;

    .col {
      width: 49% !important;
      padding: 0 20px 0 0;

      &:nth-child(2) {
        padding: 0 0 0 20px;
      }
    }

    h2 {
      @include remy(18);
      margin-top: 10px;
    }
  }
}

.ml-link-col-en {
  width: 100%;
  padding-right: 5px;
  align-self: flex-end;
}

.ml-link-col-fr {
  width: 100%;
  padding-left: 5px;
  align-self: flex-end;
}

.ml-links {
  width: 100%;
  padding: 0 0 40px;

  .col {
    padding: 0 25px;
  }

  @include bp(mobile) {
    display: flex;
    padding: 0;

    .col {
      width: 49% !important;
      padding: 0 20px 0 0;

      &.ml-link-col-fr {
        padding: 0 0 0 20px;
      }
    }
  }
}

.ml-link {
  padding: 15px;
  background-color: $darkgray;
  color: #fff;
  display: block;
  width: 100%;
  max-width: 240px;
  // margin: 0 auto;
  border-radius: 6px;
  border-bottom: 1px solid transparent;
  text-align: center;
  text-transform: uppercase;
  @include remy(14);
  letter-spacing: .75px;
  font-weight: bold;

  &:hover {
    background-color: #4a4a4a;
    border-bottom: 1px solid transparent;
  }
}

// * * * * *
// Homepage (Index)

.hero--home__container {
  background-color: $bg-gray;
  min-height: 700px;

  @include bp(mobile) {
    min-height: 500px;
    height: 100vh;
  }
}

.hero--home {
  height: 80vh;
  min-height: 700px;
  object-position: center bottom;

  @include bp(mobile) {
    min-height: 500px;
    height: 100vh;
  }
}

.home__anchor {
  background: #fff;
  padding: 25px 40px;
  color: $gray;
  font-weight: bold;
  float: right;

  .down-arrow-icon {
    margin-left: 5px;
    transition: all .2s ease;
  }

  img {
    float: right;
    padding-left: 10px;
    padding-top: 5px;
  }

  &:hover {
    color: $timeline-bg;
    border-bottom: 1px solid transparent;

    .iconstroke {
      stroke: $timeline-bg;
    }

    .down-arrow-icon {
      transform: translateY(5px);
    }
  }

}

.hero-statement {
  @include remy(58);
  line-height: 68px;
  letter-spacing: .27px;
  max-width: 472px;
  font-family: $font-stack-headings;
  text-transform: none;
  color: $nav-color;
  font-weight: 700;

  @include bp(mobile) {
    margin-top: 0;
    @include remy(36);
    line-height: 42px;
    max-width: 100%;
  }

  .home & {
    max-width: 550px;
    width: 100%;

    @include bp(mobile) {
      max-width: 350px;
    }
  }
}

.home {
  a:hover {
    h2 {
      color: $button-link-color;

    }
  }
}

// * * * * *
// Archive

.post-type-archive,
.tax-event_types {
  .header-offset {
    background-color: $bg-gray;
  }
}

.post-type-archive-cannery,
.list-view {
  .header-offset {
    background-color: $cannery-bg;
    min-height: 415px;

    @include bp(mobile) {
      min-height: 0;
    }
  }

  .sticky-anchors-container,
  .sticky-anchors {
    background: $light-bg;
  }

}

.post-type-archive-event,
.tax-event_types {
  .info-event {
    background-color: $nav-color;
    padding: 30px;

    img {
      max-height: 400px;
    }
  }
}

.post-type-archive-event,
.tax-event_types {
  .header-offset {
    background-color: $timeline-bg;
    min-height: 413px;

    @include bp(mobile) {
      min-height: 0;
    }
  }
}

.post-type-archive-process {
  .header-offset {
    background-color: $process-bg;
  }

  .type-process {
    background-color: $process-bg;
    color: $nav-color;
  }

  .wp-caption-text {
    display: none;
  }
}

.page-header--archive {
  padding-top: 70px;
  padding-bottom: 100px;
  color: $nav-color;

  .col {
    margin: 0;
  }

  @include bp(mobile) {
    padding-top: 40px;
    padding-bottom: 60px;
  }
}

.tab-container {
  background-color: $cannery-bg;
  margin-top: -1px;
}

.tab-wrap {
  padding-bottom: 25px;
  margin: 0 0 0 25px;
}

.tab-item {
  display: inline-block;
  padding-right: 50px;

  a,
  button,
  input[type=button] {
    border-bottom: 1px solid transparent;
    padding-bottom: 7px;
    transition: color 0.2s ease-out;
    display: inline-block;
    line-height: 1;
    font-weight: bold;
    color: $nav-color;
  }

  img {
    width: 16px;
    height: 16px;
    margin-left: -25px;
    float: left;
  }

  button {
    font-size: 100%;
    color: $link-color;
  }

  a:hover,
  button:hover {
    color: $nav-color;
    border-bottom: 1px solid $nav-color;
  }

  .is-active {
    border-bottom: 1px solid $nav-color;
    color: $nav-color;
  }

}

.tab-item:nth-child(2) {

  img {
    margin-left: -28px;
  }

}

.tab-content {
  display: none;

  &.is-active {
    display: block;
  }
}

// Cannery and Event article
.sticky-anchors-container {
  background: $light-bg;
  height: 58px;
  z-index: $sticky-anchors;

  &.is-sticky {
    position: fixed;
    top: 90px;
    left: initial;
    z-index: 4;
  }

}

.tab-item-1 {
  position: relative;

  &::before {
    width: 200px;
    height: 62px;
    background: $nav-color;
    color: $text-color;
    content: "Use the list view to explore the canneries with a screen reader or keyboard.";
    @include remy(12);
    position: absolute;
    top: -85px;
    left: -60px;
    padding: 8px;
    border-radius: 4px;
    font-weight: normal;
    line-height: 16px;
    box-shadow: 0 1px 4px 0 #6f6e6e;
    visibility: hidden;
    opacity: 0;
    transition: all .2s ease-out;
  }

  &.tab-french {
    &::before {
      content:"Utilisez la liste pour explorer les conserveries à l’aide d’un lecteur d’écran ou d’un clavier.";
    }
  }

  &::after {
    content: "";
    top: -23px;
    left: 40px;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-top-color: $nav-color;
    border-width: 10px;
    margin-left: -10px;
    visibility: hidden;
    opacity: 0;
    transition: all .2s ease-out;
  }

  &:focus {
    &::before {
      visibility: visible;
      opacity: 1;
    }

    &::after {
      visibility: visible;
      opacity: 1;
    }
  }
}

.sticky-anchors {
  position: absolute;
  margin: 0;
  top: 0;
  left: 40px;
  z-index: $sticky-anchors;
  padding: 18px 0;

  &.is-sticky {
    position: fixed;
    top: 65px;
    left: initial;
  }

}

.sticky-anchors__item {
  display: inline-block;
  margin-right: 10px;

  a {
    color: $text-color;
    font-weight: normal;

    .post-type-archive-cannery &,
    .list-view & {
      color: $cannery-bg;
    }

    .post-type-archive-event & {
      color: $timeline-bg;
      font-weight: bold;
    }

    .active-state {
      display: none;
      visibility: hidden;
    }

    &.event-active {
      color: #333;
      border-bottom: 1px solid #333;

      .active-state {
        display: block;
        visibility: visible;
      }
    }

    &:hover {
      color: $text-color;
    }
  }
}

//credits page

.contributors-container,
.team-container {
  ul {
    li {
      display: block;
      margin-bottom: 10px;
      width: 100%;
    }
  }
}

@include bp(mobile) {

  .sticky-anchors-container {
    height: auto;

    &.is-sticky {
      top: 83px;
    }
  }

  .sticky-anchors {
    width: 100%;
    left: 0;
    padding: 18px 0;
    position: relative;
  }

  .sticky-anchors__item {
    margin-right: 5px;

    .post-type-archive-event & {
      display: inline-block;
      padding: 10px 10px 5px 0;
    }
  }

}

.tax-main-content {
  padding-top: 20px;
}

// Cannery article

.cannery-region-title {
  padding-top: 50px;
  padding-bottom: 50px;
  margin: 0;
  font-weight: bold;
}

.article-cannery {
  margin-bottom: 140px;
  padding-top: 80px;
  position: relative;

  .post-type-archive-process & {
    padding-top: 0;
  }

  .card-link {
    margin-left: 50px;

    @include bp(mobile) {
      margin-left: 0;
    }
  }

  @include bp(mobile) {
    margin-bottom: 80px;
    padding-top: 0;
  }
}

//image captions

.img-caption-credit {
  @include remy(11);
  text-align: left;
  padding: 5px;
  line-height: 14px;
  padding-left: 30%;
}

// Cannery Google Map

.cannery-map {
  height: 900px;
  overflow: hidden;
  background-color: $newgray;
  margin-bottom: -100px;

  @include bp(mobile) {
    height: 1000px;
  }
}

.marker__inner {
  max-width: 500px;
  width: 100%;
  visibility: hidden;

  &.content-visible {
    visibility: visible;
  }
}

.marker__main-content {
  padding: 25px 20px;
  background-color: #fff;
}

.marker__img-wrap {
  position: relative;
  overflow-y: hidden;
}

.maker__img {
  width: 100%;
  height: 400px;

  .info-overlay__img-wrap & {
    height: auto;
  }
}

.marker__icon {
  width: 60px;
  position: absolute;
  top: 320px;
  left: 20px;
  transform: translate(0, 0);
  border-radius: 50%;
}

// Cannery Map Year Slider

.map-slider-wrap {
  max-width: 660px;
  padding: 20px;
  width: 100%;
  position: absolute;
  top: 25px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;

  @include bp(mobile) {
    display: flex;
    justify-content: space-between;
  }
}

.map-slider__year {
  width: 10%;
  font-weight: bold;
}

.map-slider__slider-els {
  width: 90%;
}

.map-slider__min,
.map-slider__max {
  width: 10%;
  text-align: center;
}

.map-slider__slider {
  width: 70%;
}

.noUi-connect {
  background: #316785;
  box-shadow: none;
  transition: background .45s;
}

.noUi-handle {
  background: #ccc;
  box-shadow: none;
  border: 0;
}

.noUi-horizontal .noUi-handle {
  width: 17px;
  height: 27px;
  top: -8px;
}

.noUi-handle::after,
.noUi-handle::before {
  display: none;
}

.noUi-target {
  background: #e8e8e8;
  border-radius: 20px;
  border: 0;
  box-shadow: none;
  height: 10px;
  margin-top: 6px;
}

@include bp(mobile) {
  .map-slider__year {
    width: 15%;
  }

  .map-slider__min {
    width: 15%;
    text-align: left;
  }

  .map-slider__slider {
    width: 60%;
  }

}

// Cannery Map Offcanvas Slider

.cannery-map-overlay {
  background-color: #000;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: opacity 0.5s ease-out;

  .has-map-overlay & {
    opacity: 0.5;
    visibility: visible;
    pointer-events: auto;
  }
}

$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);

.cannery-map-offcanvas {
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 500px;
  pointer-events: none;
  transform: translateX(-500px);
  will-change: transform;

  transition: transform 0.5s $ease-out-expo;

  figure {
    margin: 0;
  }

  .has-map-overlay & {
    pointer-events: auto;
    transform: translateX(0);
  }

  h2 {
    font-family: $font-stack-subheadings;
    @include remy(23);
    letter-spacing: 1px;
  }

  a.card-link {
    margin-top: 25px;
  }

  @include bp(mobile) {
    max-width: 90%;
  }
}

.cannery-overview {
  h2 {
    @include headings();
    @include remy(30);
  }
}

// Info Overlay: cannery, event, process

//content

.info-overlay__inner {
  max-width: 900px;
  width: 100%;
  overflow-y: auto;
  -ms-overflow-style: none;
}

.info-overlay__main-content {
  padding: 0;
  background-color: #fff;
  width: 50%;
  float: left;

  h2 {
    font-family: $font-stack-subheadings;
    @include remy(23);
    letter-spacing: 1px;
    margin-top: 0;
  }

  @include bp(mobile) {
    width: 100%;
  }

}

.info-overlay__img-wrap {
  position: relative;
}

// overlay


.info-overlay-content {
  display: none;
  -ms-overflow-style: none;
}

.has-info-overlay {

  .info-overlay-content-container {
    overflow-y: auto;
    height: auto;
    max-height: 100%;
    -ms-overflow-style: none;

    .info-overlay-content {
      display: block;
      -ms-overflow-style: none;
    }

  }

}

.archive-info-overlay {
  background-color: rgba(#000, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: $map-info;
  overflow: auto;

  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: opacity 0.5s ease-out;
  -ms-overflow-style: none;

  .has-info-overlay &,
  .has-image-overlay & {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;

  }

  .info-overlay__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-overflow-style: none;
  }

  .info-overlay__main-content {
    padding-top: 25px;
    -ms-overflow-style: none;
  }

}

.info-overlay-content-container {
  background: #fff;
  border-radius: 12px;
  overflow: hidden;
  display: none;
  width: 100%;
  max-width: 900px;
  position: fixed;
  z-index: 99;
  top: 50%;
  left: 50%;
  padding: 60px 30px;
  transform: translate(-50%, -50%);
  -ms-overflow-style: none;

  .has-info-overlay & {
    display: block;
  }

  .info-overlay__img-wrap {
    width: 50%;
    float: left;
    padding-right: 30px;
    padding-bottom: 30px;

    @include bp(mobile) {
      width: 100%;
      float: none;
      padding: 0;
    }
  }
}

.btn-close-overlay {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
  @include remy(20);
  font-weight: bold;
  z-index: 10;
  outline: 0;

  img {
    width: 16px;
    float: right;
    margin: 10px;
  }
}

.image-overlay {
  background-color: rgba(#000, 0.7);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: $map-info;
  overflow: auto;

  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: opacity 0.2s ease-out;
  -ms-overflow-style: none;

  .has-image-overlay & {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;

  }
}

.image-overlay-content-container {
  display: none;
  overflow: auto;
  position: fixed;
  z-index: 99;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-overflow-style: none;
  width: auto;
  max-width: 90%;
  max-height: 90vh;
  padding: 60px 30px;

  .image-overlay-content {
    position: relative;

    figure {
      margin: 0;
      position: relative;
      max-height: 90vh;

      .overlay-img-caption,
      .full-caption {
        max-width: 450px;
        padding: 10px 20px 10px 0;
        left: 0;
        bottom: 0;
        color: #fff;
        background: transparent !important;

        p {
          max-width: 560px;
          @include remy(11);
          line-height: 18px;
          letter-spacing: .3px;
        }

      }

      img {
        min-width: 600px;
        margin: 0 auto;
        max-width: 100%;
        width: 100%;
        height: auto;
      }
    }

  }

  .has-image-overlay & {
    display: block;
  }

}

.overlay-img-caption {
  max-width: 450px;
  padding: 10px 20px 10px 0;
  left: 0;
  bottom: 0;
  color: #fff;
  background: transparent !important;

  p {
    max-width: 560px;
    @include remy(11);
    line-height: 18px;
    letter-spacing: .3px;
  }
}

@include bp(mobile-tablet) {
  .image-overlay-content-container {
    width: 100%;

    .image-overlay-content {
      figure {
        width: 100%;

        img {
          min-width: 100%;
          max-width: 100%;
          width: 100%;
        }
      }
    }
  }
}

.zoom-image {
  cursor: zoom-in;

  &:focus {
    outline-color: $brightblue !important;
    outline-offset: 2px !important;
    outline-style: solid !important;
    outline-width: 2px !important;
  }
}

.show-on-zoom {
  display: none;

  .has-image-overlay & {
    display: block;
  }
}

.image-popup-content {
  display: none;

  .image-overlay-content-container & {
    display: block;
  }
}

.full-caption {
  display: none;

  .image-overlay-content-container & {
    display: block;
  }

  p {
    color: #fff;
  }
}

.btn-close-image-overlay {
  position: absolute;
  top: 25px;
  right: 25px;
  color: #fff;
  width: 25px;
  height: 25px;
  padding: 0;
  @include remy(20);
  font-weight: bold;
  z-index: 10;
  outline: 0;
  text-align: center;

  img {
    width: 16px;
    margin: 0 auto;
  }
}


// Event article
.event-filter-container {
  background-color: $timeline-bg;
  z-index: 3;
  margin-top: -1px;
}

.event-filter-wrap {
  padding-bottom: 25px;
  margin: 0;
}

.event-filter-item {
  display: inline-block;
  padding-right: 40px;

  a,
  button {
    border-bottom: 1px solid transparent;
    padding-bottom: 10px;
    transition: color 0.2s ease-out;
    display: inline-block;
    line-height: 1;
    font-weight: bold;
    outline: 0;
    color: $nav-color;
    transition: color .2s ease;
  }

  &:nth-child(2) {
    button::after {
      content: url("/app/themes/bccanneries/assets/images/chevron-light.svg");
      padding-left: 10px;
    }

    .filter-active::after {
      content: url("/app/themes/bccanneries/assets/images/chevron-up.svg");
      padding-left: 10px;
    }
  }

  .active-state {
    display: none;
    visibility: hidden;
  }

  button {
    font-size: 100%;
    color: $nav-color;

    &.filter-active {
      color: $text-color;
      transition: color .2s ease;
    }
  }

  .is-active {
    border-bottom: 1px solid $nav-color;
    color: $nav-color;

    .active-state {
      display: block;
      visibility: visible;
    }
  }

  img {
    float: right;
    padding-left: 7px;
    padding-top: 7px;
  }
}

.event-filter-list {
  position: absolute;
  left: 135px;
  top: -40px;
  display: block;
  padding: 70px 28px 18px;
  z-index: -1;
  background-color: $light-bg;
  box-shadow: 0 1px 4px 0 #6f6e6e;
  min-width: 220px;

  &.is-hidden {
    display: none;
  }

  a.is-active {
    color: $link-color;
  }

  li {
    margin-bottom: 12px;
  }
}

html:lang(fr-CA) {
  .event-filter-list {
    left: 223px;
  }
}

.timeline {
  position: absolute;
  left: 39px;
  top: 20px;
  width: 2px;
  background: $darkblue;
  min-height: 200px;
  height: 100%;

  @include bp(mobile) {
    left: 20px;
  }

}

.tax-main-content--event {
  padding-top: 0;
  padding-left: 50px;

  @include bp(mobile) {
    padding-left: 40px;
  }

}

.event-year-title {
  @include headings();
  margin: 0 0 25px;
  @include remy(42);
  position: relative;

  &::before {
    display: block;
    width: 14px;
    height: 14px;
    background: $darkblue;
    border: 2px solid $darkblue;
    position: absolute;
    content: "";
    border-radius: 50%;
    top: 46%;
    transform: translateY(-50%);
    left: -56.5px;

    @include bp(mobile) {
      left: -46.5px;
    }
  }

  @include bp(mobile) {
    @include remy(24);
  }

}

.event-intro {

  h3 {
    font-family: $font-stack-subheadings;
    @include remy(23);
    line-height: 29px;
    letter-spacing: 1px;
  }
}

.article-event,
.info-event,
.info-process {
  margin: 160px 0;
  position: relative;

  @include bp(mobile-tablet) {
    img {
      height: auto;
    }
  }

  @include bp(mobile) {
    margin: 80px 0;

    img {
      height: auto;
    }
  }
}

.article-event {

  .flex-grid {
    background-color: $timeline-bg;
    color: #fff;
    position: relative;
  }

}

.article-event__icon {
  position: absolute;
  bottom: 20px;
  left: 20px;
  border-radius: 50%;
  width: 60px;
  z-index: $event-icons;

  @include bp(mobile) {
    bottom: 20px;
    top: auto;
    height: auto;

    .article-cannery & {
      top: 170px;
    }
  }

}

.artifact-image {
  margin: 0 auto;
  padding: 40px 20px;
}

.post-number {
  position: absolute;
  @include remy(72);
  font-family: 'Miso Bold', sans-serif;
  font-weight: 400;
  color: #c2c2c2;
  top: -50px;
  right: 30px;
  z-index: 1;

  @include bp(mobile) {
    right: 10px;
  }
}

.article-cannery:nth-child(even) .post-number {
  left: 30px;
  right: auto;

  @include bp(mobile) {
    left: 10px;
  }
}

.event-year-block {
  margin-bottom: 80px;

  img {
    height: 400px;

    &.article-event__icon {
      height: auto;
    }

  }

  @include bp(mobile-tablet) {
    img {
      height: auto;
    }

    .article-event {
      img {
        height: 400px;

        &.article-event__icon {
          height: auto;
        }
      }
    }
  }

  @include bp(mobile) {
    .article-event {
      .wp-caption-text {
        display: none;
      }
    }
  }

}

.event-year,
.info-event-year {
  position: relative;
  top: auto;
  left: auto;
  display: block;
  margin-bottom: 20px;
  font-weight: bold;
  font-family: $font-stack-subheadings;
  @include remy(23);
  line-height: 29px;
  letter-spacing: 1px;

  &::before {
    display: block;
    width: 28px;
    height: 28px;
    background: $darkblue;
    border: 0 solid $darkblue;
    position: absolute;
    content: "";
    text-align: center;
    padding-bottom: 2px;
    @include remy(18);
    color: $nav-color;
    border-radius: 50%;
    top: 12px;
    transform: translateY(-50%);
    left: -94px;

    .event_types-economic &,
    .event_types-economic-fr &,
    .event_types-industry &,
    .event_types-industry-fr & {
      content: url("/app/uploads/2018/01/icon-ecomomy.svg");
    }

    .event_types-enviroment &,
    .event_types-enviroment-fr & {
      content: url("/app/uploads/2018/01/icon-environment.svg");
    }

    .event_types-social-issues &,
    .event_types-social-issues-fr & {
      content: url("/app/uploads/2018/01/icon-social.svg");
    }

    .event_types-technological-changes &,
    .event_types-technological-changes-fr & {
      content: url("/app/uploads/2018/01/icon-technology.svg");
    }

    .event_types-global-events &,
    .event_types-global-events-fr & {
      content: url("/app/uploads/2018/01/icon-global.svg");
    }

    .event_types-transportation-shipping &,
    .event_types-transportation-shipping-fr & {
      content: url("/app/uploads/2018/01/icon-transportation.svg");
    }

    @include bp(mobile) {
      left: -84px;
    }

  }

}

.event-year {
  &::before {
    left: -64px;

    @include bp(mobile) {
      left: -54px;
    }
  }
}

.info-event-title {
  @include remy(20);
  margin: 20px 0 0;
  font-weight: bold;
  text-transform: none;
  font-family: $font-stack-subheadings;
  @include remy(23);
  line-height: 29px;
  letter-spacing: 1px;
}

.info-event__content {

  figure {
    max-height: 400px;
    overflow: hidden;
  }

  @include bp(mobile) {
    margin-bottom: 40px;
  }
}

// Event info default hidden
.btn-open-event-info {
  color: $button-link-color;
  text-transform: uppercase;
  letter-spacing: .75px;
  font-weight: bold;
  display: inline-block;
  margin-top: 40px;
  @include remy(14);
  transition: color .2s ease-out;

  &:hover {
    color: $text-color;
    border-bottom: 1px solid $text-color;
  }

}

// Process

.process-intro {
  width: 100%;
  max-width: 700px;
  margin: 100px 0;

  .process-quote {
    @include remy(20);
    font-style: italic;
    font-weight: bold;
    line-height: 23px;
    max-width: 500px;
    font-family: $font-stack-headings;
    text-transform: none;
    margin-bottom: 24px;
  }

  @include bp(mobile) {
    margin: 80px 0 40px;
  }
}

.card,
.info-process {
  h2,
  h3 {
    @include headings();
    text-transform: none;
    @include remy(23);
    letter-spacing: 1px;
  }
}

.card__desc h2 {
  @include headings();
  margin-bottom: 15px;
  @include remy(27);
  text-transform: uppercase;
  margin-top: 10px;

  a {
    transition: all .2s ease-out;
  }

  &:hover {
    a {
      color: $button-link-color;
      border-bottom: 1px solid $button-link-color;
    }
  }
}

.card__game {
  order: -1;

  h2 {
    @include headings();
    @include remy(27);

    a {
      transition: all .2s ease-out;
    }

    &:hover {
      a {
        color: $button-link-color;
        border-bottom: 1px solid $button-link-color;
      }

    }
  }

  a.card__link-text {
    @include buttons;
    transition: all .2s ease;

    &:hover {
      color: $nav-color;
      background: $button-link-color;
    }
  }
}

.card-link {
  color: $button-link-color;
  text-transform: uppercase;
  letter-spacing: .75px;
  font-weight: bold;
  display: inline-block;
  margin-top: 40px;
  @include remy(14);
  transition: color .2s ease-out;

  &:hover {
    color: $nav-color;
    border-bottom: 1px solid $nav-color !important;
  }

  .post-type-archive-cannery &,
  .list-view & {
    &:hover {
      border-bottom: 1px solid $button-link-color !important;
    }
  }

}

.card-link__game {
  color: $text-color;
  font-weight: 600;
  @include remy(14);
  display: inline-block;
  margin-bottom: 30px;
}

//Process Article

.single__share a {
  color: $button-link-color;

  &:hover {
    color: $text-color;
  }
}

article.type-process {
  &:nth-child(even) {
    .card-thumb {
      order: 2;
    }

    .card__icon {
      left: auto;
      right: 20px;

      @include bp(mobile) {
        left: 20px;
        right: auto;
      }
    }
  }
}

.process-artifact {
  position: absolute;
  right: 20px;
  bottom: -180px;
  height: 264px;
  width: auto;

  @include bp(mobile) {
    height: 164px;
    bottom: -100px;
  }
}

// * * * * *
// Page general

.page-header--general {
  padding-top: 80px;
  padding-bottom: 80px;
  color: $nav-color;

  .col {
    margin: 0;
  }
}

.page-header {
  align-items: flex-start;

  h1 {
    margin-top: 15px;
  }

  p {
    @include headings();
    text-transform: none;
    @include remy(21);
    letter-spacing: 1px;
    line-height: 26px;

    @include bp(mobile) {
      @include remy(20);
      line-height: 24px;
    }
  }
}


@include bp(mobile) {
  .page-header h1 {
    margin-top: 0;
    @include remy(36);
    line-height: 42px;
    max-width: 100%;
  }
}

// * * * * *
// Site map

.site-map__list {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  max-width: 100%;
  margin: 0 auto;

  .sub-menu {
    margin-top: 10px;
    margin-bottom: 20px;
    padding-left: 20px;
  }

  .site-map__item,
    li {
      width: 100%;
      margin-bottom: 5px;

    a {
      @include remy(18);
    }
  }
}

.site-map__item {
  width: 100%;
  margin-bottom: 5px;

  a {
    @include remy(18);
  }
}

// * * * * *
// Feedback

.feedback-forms {
  max-width: 600px;

  h2 {
    margin-top: 80px;
    font-family: 'Miso-Chunky' ,sans-serif;
    font-weight: 700;
    letter-spacing: 1.34px;
    text-transform: uppercase;
  }

  a.feedback-link {
    padding: 15px 30px;
    background-color: $text-color;
    color: #fff;
    display: block;
    width: 100%;
    max-width: 300px;
    border-radius: 6px;
    text-align: center;
    text-transform: uppercase;
    @include remy(14);
    letter-spacing: .75px;
    font-weight: 700;
    margin: 80px 0;
    text-decoration: none;

    &:hover {
      background: $gray !important;
    }
  }

  input,
  textarea {
    border: 1px solid #d3d3d3;
  }

  input[value="Submit"] {
    appearance: none;
    background-color: transparent;
    padding: 10px 25px;
  }

  input[type=button] {
    padding: 15px 30px;
    background-color: $darkgray;
    color: #fff;
    display: block;
    width: 100%;
    max-width: 240px;
    border-radius: 6px;
    text-align: center;
    text-transform: uppercase;
    @include remy(14);
    letter-spacing: .75px;
    font-weight: bold;

    &:hover {
      background: $gray !important;
    }
  }


  .nf-form-fields-required {
    @include remy(15);
    margin: 10px 0;
  }

  .nf-error-msg,
  .ninja-forms-req-symbol {
    color: $cannery-bg;
  }
}

// * * * * *
// Feedback

.teachers-resources__resource {
  border-top: 1px solid $separator-color;
  padding-top: 60px;
  margin-top: 0;

  h3 {
    margin-top: 50px;
  }

  .teachers-resources & {
    margin-top: 60px;
  }
}

.teachers-resources__content {
  overflow: hidden;

  h2 {
    @include headings();
  }
}

.download-item {
  padding-right: 50px;
  margin-bottom: 25px;
}

.download-item__link {
  @include buttons;
  display: inline-block;
  border-color: #c2c2c2;
  color: $text-color;
  font-family: $font-stack;
  text-transform: none;
  padding: 14px 24px;
  transition: all .2s ease-out;

  img {
    float: left;
    padding-right: 20px;
    padding-top: 3px;
    margin: 0;
  }
}

.teachers-resources {
  .content-wrap {
    h2,
    h3 {
      margin-top: 60px;
    }
  }
}

.credits {
  .u-mt80 {
    margin-top: 20px;
  }

  .credit-intro {
    padding-bottom: 60px;
  }

  .footer {
    margin-top: 50px;
  }
}

@include bp(mobile) {
  .credits {
    .u-mt80 {
      margin-top: 20px;
    }
  }

  .teachers-resources__resource {
    padding-top: 40px;
    margin-top: 0;
  }
}

.sources {
  li {
    margin-bottom: 10px;
  }
}

//object-fit fallback

&.compat-object-fit {
  background-size: cover;
  background-position: center center;

  .u-ofit { // hide image if object fit is not supported - opacity to 0 for the link area
    opacity: 0;
  }
}

