// * * * * *
// Game

.game-cannery-setup {
  .header-offset,
  .bg-color {
    background-color: $game-bg;
  }
}

.game {

  .header-offset {
    background-color: $game-bg;
    padding-bottom: 60px;
    min-height: 0;

    @include bp(mobile) {
      padding-bottom: 20px;
    }
  }

  .content-wrap li {
    margin-bottom: 20px;
  }
}

.game-cannery {

  .header-offset {
    background-color: $game-bg;
    padding-bottom: 60px;
    min-height: 0;

    @include bp(mobile) {
      padding-bottom: 20px;
    }
  }
}


.game__start-wrap {
  position: absolute;
  display: block;
  max-width: 400px;
  top: 50px;
  left: 66px;
}

.btn-game {
  margin-top: 50px;
  appearance: none;
  padding: 20px 50px;
  font-weight: bold;
  display: inline-block;
  @include buttons;
  border: 0;
}

.btn-game--start {
  color: $text-color;

  &:hover {
    border: 0;
    background-color: $text-color;
    text-decoration: underline;
  }
}

.btn-game--next {
  width: 389px;
  max-width: 100%;
  border: 0 !important;
  box-shadow: 0 1px 4px 0 #6f6e6e;
  letter-spacing: .75px;
  color: $text-color;
  position: relative;
  display: block;
  margin: 80px auto;

  .game-card & {
    margin: 80px auto 0;
  }

  &:hover {
    border: 0;
    background-color: $nav-color;
    color: $game-bg;
    text-decoration: underline;
  }

}

.btn-game--disabled {
  color: $headergray;
  cursor: not-allowed !important;

  &:hover {
    border: 0;
    background-color: #fff;
    color: $headergray;
  }

  &.nextactive {
    color: $text-color;
    cursor: pointer !important;

    &:hover {
      border: 0;
      background-color: #fff;
      color: $game-bg;
    }
  }
}

.btn-game--right {
  float: right;
  padding: 22px 18px 18px;
}

.mark-false {
  color: #cc4747;
  font-weight: bold;
}

.mark-true {
  color: #2c585e;
  font-weight: bold;
}

// * * * * *
// Game setup

.game-main {
  input,
  textarea {
    border: 2px solid $headergray;
    border-radius: 6px;
    font-weight: bold;
    padding: 18px 18px 15px;

    &:hover {
      background: $nav-color;
      color: $game-bg;
    }

    h2 {
      @include remy(16);
    }
  }

  label {
    background: $nav-color;
    border-radius: 6px;
    width: 100%;
    padding: 18px;
    box-shadow: 0 1px 4px 0 #6f6e6e;
    font-weight: bold;
    color: $game-bg;
    display: block;
    cursor: pointer;

    &:hover {
      background: $game-bg;
      color: $nav-color;
    }
  }

  input[type="radio"] {
    margin-bottom: 10px;

    .game-cannery-setup & {
      visibility: visible;
      margin-bottom: 10px;
    }

    &:checked + label {
      background: $game-bg;
      color: $nav-color;
    }
  }
}

.game-setup-component {
  align-items: center;
  background: #fff;
  box-shadow: 0 1px 4px 0 #6f6e6e;
  border-radius: 6px;
  padding: 30px;
  max-width: 784px;
  margin: 0 auto;
  margin-top: 40px;

  h3,
  h2 {
    font-weight: bold;
    margin-top: 0;
    line-height: 25px;
    @include remy(18);
  }
}

.game-setup-component-last {
  text-align: center;
}

.game-input-header {
  text-align: center;
  margin-bottom: 15px;
  display: block;
}

.game-user-name-input {
  display: block;
  margin: 0 auto;
  width: 100%;
  padding: 10px;
}

.game-location,
.game-era {
  text-align: center;

  input {
    margin: 0;
  }
}

.game-era {
  display: block;
}

// * * * * *
// Game review

.game-review,
.game-card {
  h3,
  h2 {
    font-weight: bold;
    line-height: 25px;
    @include remy(18);
  }
}

.game-review__inputs-wrap {
  width: 90%;
  max-width: 500px;
  text-align: left;
  margin: 0 auto;
  position: relative;
  background-color: rgba(255, 255, 255, 0.5);
}

.game-review__inputs {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
  text-align: center;

  h3,
  h2 {
    font-family: $font-stack-headings;
    color: $game-bg;
    @include remy(38);
    font-weight: bold;
    margin-bottom: 10px;
    text-transform: capitalize;
  }

  p {
    font-weight: bold;
  }

  th,
  td {
    width: 100%;
  }
}

// * * * * *
// Game Quiz

.game-quiz-option {
  overflow: auto;

  label {
    width: 90%;
    float: right;
  }
}

.game-quiz-option-result {
  p {
    margin-left: 40px;
  }

  img {
    width: auto;
    float: left;
    margin-left: -40px;
  }
}
// * * * * *
// Game Overview
.game-overview-score,
.game-overview-main {
  h1,
  h2 {
    text-align: center;
    text-transform: capitalize;
  }

  h3 {
    text-transform: capitalize;
  }

  .col {
    padding: 2%;
  }
}

.game-overview-score {
  max-width: 511px;
  margin: 0 auto;

  h1 {
    color: $game-bg;
    @include remy(38);
    line-height: 45px;
    text-transform: none;
  }

  .game-overview & {
    h1 {
      padding: 0;
      margin: 0;
    }
  }
}

.game-overview-score__score {
  text-align: center;
  @include remy(20);
}

.game-overview__btn-wrap {
  text-align: center;

  .btn-game {
    width: 100%;
    max-width: 390px;
    margin: 80px auto;
    background-color: $game-bg;
    color: #fff;
    text-align: center;
    font-family: $font-stack;
    @include remy(14);
    font-weight: bold;
    padding: 18px;

    &:hover {
      border: 0;
      background-color: $text-color;
    }
  }
}

// * * * * *
// Vue version

[v-cloak] {
  display: none;
}

.game-page-enter-active {
  transition: opacity 0.5s ease;
}

.game-page-leave {
  opacity: 0;
}

.game-page-enter {
  opacity: 0;
}

//new design

.game-card {
  background: #fff;
  box-shadow: 0 1px 4px 0 #6f6e6e;
  border-radius: 6px;
  padding: 30px;
  max-width: 450px;
  margin: 0 auto;
  margin-top: 80px;
  text-align: center;
  animation-name: cardFadeIn;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;

  &.game-quiz-option {
    padding: 0;
    cursor: pointer;

    input {
      margin: 22px 0 20px 20px;
      cursor: pointer;
    }

    label {
      padding: 20px;
      cursor: pointer;
    }
  }

  .game-quiz-conponent & {
    text-align: left;
    margin: 20px 0 0;
  }

  h3 {
    font-weight: bold;
  }

  .btn-game {
    @include buttons;
    width: 100%;
    background-color: $game-bg;
    color: #fff;
    text-align: center;
    font-family: $font-stack-subheadings;
    margin-top: 10px;
    font-weight: bold;
    margin-top: 20px;
    border: 0;

    &:hover {
      border: 0;
      background-color: $text-color;
    }
  }

  &.active {
    background: $game-bg;
    color: $white;
  }
}

@keyframes cardFadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.game-overview-main {
  max-width: 624px;
  text-align: left;

  .game-overview & {
    margin-top: 40px;
  }

  h1 {
    text-align: left;
    font-family: $font-stack;
    @include remy(20);
    font-weight: bold;
  }

  h2 {
    text-align: left;
    font-family: $font-stack;
    @include remy(16);
    font-weight: bold;
  }

  h3 {
    // margin-top: 40px;
    margin-bottom: 40px;
    font-weight: normal;
    @include remy(16);
    line-height: 25px;
  }

  p {
    margin-left: 30px;
  }

  img {
    width: auto;
    float: left;
    margin-left: -30px;
    margin-top: 7px;
  }
}

.game-overview-score {
  h1 {
    font-family: $font-stack-headings;
    color: $game-bg;
    @include remy(38);
    line-height: 45px;
    text-transform: none;
    font-weight: 500;
    text-align: center;
    padding: 30px 0 60px;
  }
}

//game timeline

.game-timeline {
  height: 60px;
  width: 100%;
  background: $nav-color;

  .container {
    padding: 20px 40px;
  }

  .setup-complete {
    fill: none;

    &.active {
      fill: #2c585e;
    }
  }

  .quiz-1,
  .quiz-2,
  .quiz-3,
  .quiz-4,
  .quiz-5 {
    fill: $nav-color;

    &.active {
      fill: #2c585e;
    }
  }

}

//game images

.quiz-setup-bg {
  background-size: cover;
  background-repeat: no-repeat;
  overflow: auto;
  padding: 20px 0 100px;
}

.quiz-bg-image {
  background-color: $light-bg;
  position: fixed;
  z-index: -9999;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
}

.print-button {
  text-align: center;
  font-weight: bold;
  padding-top: 50px;

  img {
    width: 24px;
    display: inline-block;
    margin-right: 14px;
    float: none;
    margin-left: auto;
    margin-top: auto;
  }

  a:hover {
    border-bottom: 1px solid transparent;
    color: $game-bg;
  }
}

@media print {
  .quiz-bg-image,
  footer,
  .print-button,
  .game-overview__btn-wrap,
  .game-timeline,
  .header-offset,
  .nav-primery,
  .nav-lang {
    display: none;
  }

  .header--sticky {
    position: relative;
    background-color: #fff;
    box-shadow: none;
  }

  .header.is-shrank {
    box-shadow: none;
  }

  .game-overview-main {
    max-width: 100%;
    box-shadow: none;
    margin-top: 0;
    padding: 0;
    color: #333;

    h3 {
      @include remy(14);
      line-height: 16px;
      margin-top: 40px;
    }

    p {
      @include remy(14);
      line-height: 16px;
    }
  }

  .game-overview-score {
    h1 {
      @include remy(24);
      line-height: 24px;
      color: $text-color;
    }
  }

  .brand__logo {
    content: url("/app/themes/bccanneries/assets/images/bc-canneries-logo.svg");
  }

  .brand {
    width: 100%;
    float: none;
    display: block;
  }

  .header--mobile {
    position: relative;
  }
}

@include bp(mobile) {
  .game-overview-score {
    h1 {
      @include remy(28);
      line-height: 36px;
      padding: 20px 0 15px;
    }
  }
}
