.footer {
  padding-top: 100px;
  background-color: $footer-bg;

  .game &,
  .parent-pageid-56 & {
    margin-top: 0 !important;
  }

  h4,
  h2 {
    font-weight: bold;
    @include remy(15);
    text-transform: uppercase;
  }

  p {
    line-height: 23px;
  }

  .footer__info,
  .footer__resources {
    li {
      margin-bottom: 10px;
    }
  }

  a:hover {
    border-bottom: 1px solid $text-color;
  }
}

.footer__logo-wrap {
  a {
    display: inline-block;


    &:hover {
      border-bottom: 1px solid transparent;
    }
  }
}

.footer__secondary {
  padding: 40px 0 25px;
  justify-content: space-between;
  @include remy(13);

  .menu-item {
    display: inline-block;
    margin-left: 40px;
  }

  p {
    order: -1;
  }
}

.footer__desc {

  a {
    display: inline-block;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 1px;
    padding-top: 15px;
  }

}

@include bp(mobile-tablet) {
  .footer {
    padding-top: 50px;
  }
}

@include bp(mobile) {

  .footer {
    padding-top: 30px;
  }

  .footer__desc {
    margin-bottom: 40px;
  }

  .footer__secondary {

    ul {
      text-align: left;

      .menu-item {
        margin: 20px 20px 0 0;
      }

    }

    p {
      order: 1;
      text-align: left;
    }

  }

}

//back to top link

.back-to-top {
  position: fixed;
  right: 5px;
  bottom: 5px;
  width: 60px;
  height: 60px;
  background: rgba(#e0e0de, 0.5);
  // color: #fff;
  @include remy(32);
  font-family: $font-stack-headings;
  text-align: center;
  padding-top: 18px;
  cursor: pointer;
  z-index: 999;

  img {
    margin: 0 auto;
  }
}
