// Grid settings
$enable-flex:           true;
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors

$orange:                #c34522;
$beige:                 #f7f4ed;
$darkblue:              #2a4f63;
$brightblue:            #4cabd4;
$blue:                  #457587;
$lightblue:             #316785;
$lighterblue:           #edf3f5;
$mutedblue:             #5091ad;
$green:                 #037c70;
$darkgreen:             #2c585e;
$darkgray:              #333;
$gray:                  #4a4a4a;
$lightgray:             #e8eced;
$graygreen:             #d4d9d7;
$white:                 #fff;
$newgray:               #e0e0de;
$headergray:            #9b9b9b;

$text-color:            $darkgray;
$link-color:            $darkgray;
$button-link-color:     $lightblue;
$button-bg-color:       $lightgray;
$cannery-color:         $orange;
$timeline-color:        $brightblue;
$process-color:         $green;
$process-card-bg:       $lighterblue;
$cannery-bg:            $orange;
$timeline-bg:           $blue;
$process-bg:            $green;
$game-bg:               $darkgreen;
$nav-color:             $beige;
$sticky-nav-color:      $headergray;
$footer-bg:             $newgray;
$light-bg:              $beige;

// to be removed
$brand-primary:         #27ae60;
$text-black:            #212121;
$bg-gray:               #2d3f48;
$separator-color:       #979797;


// Sizes
$card-height:						500px;
$card-height-sm:				400px;
$card-height-mobile:    auto;
$header-height:					145px;
$icon-offset: calc($card-height - 90px);

// z-index

$sticky-header-index: 6;
$header-index: 5;

$sticky-anchors: 2;
$event-icons: 1;
$map-info: 10;

$event-filter: 3;
