html,
body,
button,
input,
a,
img,
.menu-toggle {
  &:focus {
    outline-color: $brightblue !important; // #4D90FE
    outline-offset: 2px !important;
    outline-style: solid !important;
    outline-width: 2px !important;
  }
}

.header,
.header-offset,
.tab-container,
.single__breadcrumb {
  button,
  input,
  a,
  img,
  .menu-toggle {
    &:focus {
      outline-color: $white !important; // #4D90FE
      outline-offset: 2px !important;
      outline-style: solid !important;
      outline-width: 2px !important;
    }
  }

  .toggled {
    button,
    input,
    a,
    img,
    .menu-toggle {
      &:focus {
        outline-color: $brightblue !important; // #4D90FE
      }
    }
  }
}

.article-event {
  .card {
    button,
    input,
    a,
    img,
    .menu-toggle {
      &:focus {
        outline-color: $white !important; // #4D90FE
        outline-offset: 2px !important;
        outline-style: solid !important;
        outline-width: 2px !important;
      }
    }
  }
}

.post-type-archive-process {
  .type-process {
    .card {
      button,
      input,
      a,
      img,
      .menu-toggle {
        &:focus {
          outline-color: $white !important; // #4D90FE
          outline-offset: 2px !important;
          outline-style: solid !important;
          outline-width: 2px !important;
        }
      }
    }
  }
}

.btn-game {
  .caret {
    display: inline-block;
    transition: all .2s ease-out;
  }

}
