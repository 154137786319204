//Fonts

@font-face {
  font-family: "Miso-Bold";
  src: url("../fonts/Miso-Bold.otf");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Miso-Chunky";
  src: url("../fonts/Miso-Chunky.otf");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

$font-stack: 'Open Sans', 'Arial', sans-serif;
$font-stack-headings: 'Volkhov', serif;
$font-stack-subheadings: 'Miso-Chunky', sans-serif;
