$mobile-width: 767px;
$tablet-width: 768px;
$desktop-width: 1024px;

$desktop-content-width: 1260px;

@mixin bp($point) {
  @if $point == desktop {
    @media (min-width: #{$desktop-width}) {
      @content;
    }
  } @else if $point == tablet {
    @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
      @content;
    }
  } @else if $point == mobile {
    @media (max-width: #{$mobile-width}) {
      @content;
    }
  } @else if $point == tablet-desktop {
    @media (min-width: #{$tablet-width}) {
      @content;
    }
  } @else if $point == mobile-tablet {
    @media (max-width: #{$desktop-width - 1px}) {
      @content;
    }
  } @else if $point == sm-desktop {
    @media (max-width: #{$desktop-content-width - 1px}) {
      @content;
    }
  }
}

@mixin remy($pxval: 16) {
  font-size: $pxval * 1px;
  font-size: ($pxval / 16) * 1rem;
}

@mixin abs-center() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin clear-abs-center() {
  position: relative;
  top: initial;
  left: initial;
  transform: none;
}

@mixin buttons() {
  font-family: $font-stack-subheadings;
  text-transform: uppercase;
  background: #fff;
  padding: 16px 25px 12px;
  color: $button-link-color;
  font-weight: 900;
  border: 2px solid $button-link-color;
  border-radius: 6px;
  transition: color .2s ease-out;
  @include remy(19);
  letter-spacing: 1px;
}

@mixin headings() {
  font-family: $font-stack-subheadings;
  font-weight: 700;
  letter-spacing: 1.34px;
  text-transform: uppercase;
}
