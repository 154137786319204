.main-navigation {
  width: 70%;
  vertical-align: middle;

  .index &,
  .french-index & {
    width: 78%;
  }

  @include bp(mobile-tablet) {
    width: 74%;

    .index &,
    .french-index & {
      width: 74%;
    }
  }

  @include bp(mobile) {
    width: 100%;
    position: absolute;
    z-index: 99;
    top: 0;

    .index &,
    .french-index & {
      width: 100%;
    }

    .no-js & {
      position: relative;
    }
  }
}

.nav-secondary__nav {
  margin-bottom: 0;

  .menu-item {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    margin-left: 25px;
    @include remy(13);

    a {
      color: $nav-color;
      display: block;
    }
  }

  img {
    width: 100px;
  }

  .menu-item__label {
    @include abs-center();
  }
}

.nav-lang {
  width: 20%;
  float: right;
  text-align: right;
  z-index: 99;

  ul {
    margin-top: 0;
  }

  li {

    .active-state {
      display: none;
      visibility: hidden;
    }

    a::before {
      content: "fr";
      color: #fff;
    }

    a:hover {
      &::before {
        border-bottom: 1px solid #fff;
      }
    }

    &:first-child a::before {
      content: "en";
    }

    &.wpml-ls-current-language {
      border-bottom: 1px solid #fff;

      .active-state {
        display: block;
        visibility: visible;
      }
    }
  }

  .nav-secondary__nav .menu-item {
    margin-left: 10px;
    @include remy(16);
  }

  .active {
    a {
      color: $nav-color;
    }
  }

  @include bp(mobile) {
    width: 100%;
    background: #fff;
  }

}

.nav-primary {
  width: 100%;
}

.nav-primary__nav {
  .menu-item {
    display: inline-block;
    padding-right: 5%;
  }

  a {
    border-bottom: 1px solid transparent;
    transition: all 0.2s ease-out;
    padding-bottom: 4px;
    color: $nav-color;
    text-transform: uppercase;
    @include remy(16);
    font-weight: 600;
    letter-spacing: 1px;
  }

  .menu-item:hover {
    a {
      color: $nav-color;
      border-bottom: 1px solid $nav-color;
    }
  }

  .active {
    a {
      border-bottom: 1px solid $nav-color;
      color: $nav-color;
    }
  }
}

.menu-toggle {
  display: none;

  .bar {
    background: $white;
    width: 24px;
    height: 2px;
    display: block;
    margin-bottom: 6px;
  }

  &:hover,
  &:focus {
    .bar {
      background: $white;
    }

  }

  .close-text {
    display: none;
  }

  .toggled & {

    .bar {
      background: $text-color;

      &.bar1 {
        transform: rotate(-45deg) translate(-6px, 5px);
      }

      &.bar2 {
        transform: rotate(45deg);
      }

      &.bar3 {
        display: none;
      }
    }

    .close-text {
      display: block;
    }

    .open-text {
      display: none;
    }

    .no-objecfit & {
      content: "";

      .open-text {
        position: relative;
        overflow: hidden;
        height: auto;
        width: auto;
        margin: -1px;
        padding: 0;
        border: 0;
        z-index: 9;
      }

      .close-text {
        position: relative;
        overflow: hidden;
        height: auto;
        width: auto;
        margin: -1px;
        padding: 0;
        border: 0;
        z-index: 9;
      }
    }
  }

  .no-objecfit & {
      content: "";

    .open-text {
      position: relative;
      overflow: hidden;
      height: auto;
      width: auto;
      margin: -1px;
      padding: 0;
      border: 0;
      z-index: 9;
    }
  }
}

.nav-mobile {
  display: block;

  h4 {
    font-weight: bold;
    @include remy(16);
  }

  .nav-mobile__submenu {
    clear: both;
    padding: 24px 20px;
    background: $nav-color;
    display: none;

    h2,
    .h2 {
      margin-top: 0;
      margin-bottom: 10px;
      text-transform: uppercase;
      font-weight: bold;
      @include remy(15);
      text-transform: uppercase;
    }

    ul {
      margin-bottom: 0;
      @include remy(14);
    }

  }

  @include bp(mobile) {
    display: inline-block;
  }

}

.menu-small {
  display: none;

  @include bp(mobile) {
    display: block;
  }
}

.menu-desktop {
  display: block;

  @include bp(mobile) {
    display: block;
  }
}

@include bp(mobile) {
  // Small menu.

  .main-navigation {
    left: 0;
  }

  .menu-toggle {
    position: relative;
    float: right;
    padding: 20px;
    z-index: 999;
    margin-top: 40px;

    &:focus {
      outline: 1px solid $brightblue;
    }

  }

  .nav-mobile {
    display: none;
    background: #fff;
    padding: 20px 0 0;

    @include bp(mobile) {
      .no-js & {
        display: block;
      }
    }

    .nav-mobile__submenu {
      display: block;
    }

    .nav-mobile__link {
      margin-top: 1em;
      margin-bottom: 1em;
      width: auto;
      float: none;
      padding-left: 20px;
      text-transform: uppercase;
    }

    .nav-primary {
      width: 100%;
      padding: 0 20px 40px;

      ul {
        margin: 0;
      }
    }

    .menu-small {
      display: inline-block;
      padding: 24px 20px 10px;
      background: $footer-bg;

      p {
        @include remy(14);
      }

      &.nav-mobile__link {
        background: #fff;
      }
    }

    .menu-item:hover {
      a {
        color: $text-color;
        border-bottom: 1px solid transparent;
      }
    }

    a,
    a:hover,
    a:focus,
    a:active {
      color: $text-color;
      border-bottom: 1px solid transparent;
      display: inline-block;
    }
  }

  .nav-lang {
    position: relative;
    padding-right: 20px;
    right: 37px;
    top: -3px;
    width: 200px;
    background-color: transparent;

    ul {
      background-color: transparent;
    }
  }

  .main-navigation.toggled {
    z-index: 9999;
    overflow-y: scroll;
    height: 100vh;
  }

  .menu-toggle,
  .main-navigation.toggled .nav-mobile {
    display: block;
  }

  .nav-mobile {
    width: 100%;
    float: none;
  }

  .nav-mobile {

    .menu-item {
      display: block;
      width: 100%;
      padding: 5px 0 15px;
    }

    .nav-lang .menu-item {
      display: inline-block;
      width: auto;
      padding: 0;
    }


    .active {
      a {
        color: $text-color;
        border-color: $text-color;
      }
    }
  }

}
