// ============================================================================
//  Custom reset
// ============================================================================
html {
  font-size: 100%;
  box-sizing: border-box;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: swap;
  text-size-adjust: 100%;
  line-height: 1.4;
  margin: 0;
  position: relative;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: lighter;
}

a {
  text-decoration: none;
}

input {
  // Nasty Firefox hack for inputs http://davidwalsh.name/firefox-buttons
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
    margin-top: -1px;
    margin-bottom: -1px;
  }
}

input[type='radio'],
input[type='checkbox'] {
  margin-right: 6px;
}

textarea,
button,
select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

button {
  background: none;
  border: 0;
  cursor: pointer;
  font-size: 100%;
  padding: 0;
}

img {
  max-width: 100%;
  //width: 100%;
  width: auto;
  height: auto;
  display: block;
  vertical-align: middle;
}

blockquote {
  padding: 0;
}

figure {
  margin: 0;
}
