// ============================================================================
//  Utility classes: u- for utility
// ============================================================================

.u-img--full {
  max-width: none;
  width: 100%;
}

.u-abs-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.u-no-bullets {
  list-style-type: none;
  padding: 0;
}

// https://css-tricks.com/places-its-tempting-to-use-display-none-but-dont/
.u-visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  z-index: 9;
}

.screen-reader-text {
  position: absolute;
  top: -9999em;
  left: -9999em;
}

.u-offscreen {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: 9;

  :focus {
    left: 10px;
    width: auto;
    height: auto;
  }

}

.u-no-scroll {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.u-fs-subpixel {
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
}

.u-ofit {
  object-fit: cover;
  max-width: none;
  width: 100%;
}

.u-position-top {
  object-position: top center;
}

.u-position-center {
  object-position: center;
}

.u-position-bottom {
  object-position: bottom center;
}

.u-video_wrapper {
  position: relative;
  padding-bottom: 56.25%; // 16:9
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.u-page-center {
  margin: 0 auto;
}

.u-show-for-mobile {
  display: none;

  @include bp(mobile-tablet) {
    display: block;
  }
}

.u-hide-for-mobile {

  @include bp(mobile-tablet) {
    display: none;
  }
}

// spacing

.u-mt0 {
  margin-top: 0;
}

.u-mt50 {
  margin-top: 50px;
}

.u-pb100 {
  padding-bottom: 100px;
}

.u-mt80 {
  margin-top: 80px;
}

.u-mt100 {
  margin-top: 100px;
}

.u-pt50 {
  padding-top: 50px;
}

.u-t50pct {
  top: 50%;
}

.u-t40pct {
  top: 40%;
}

.u-t30pct {
  top: 30%;
}

.u-t35pct {
  top: 35%;
}

.u-b50 {
  bottom: 50px;
}

.u-b0 {
  bottom: 0;
}

.u-mb270 {
  margin-bottom: 270px !important;

  @include bp(mobile) {
    margin-bottom: 140px !important;
  }
}

.u-pa25h {
  padding-right: 25px;
  padding-left: 25px;
}

.u-pa50h {
  padding-right: 50px;
  padding-left: 50px;
}

@include bp(mobile-tablet) {
  .u-pa50h,
  .u-pa25h {
    padding-left: 30px;
    padding-right: 0;
  }
}

@include bp(mobile) {
  .u-pa50h,
  .u-pa25h {
    padding-left: 0;
    padding-right: 0;
  }
}

.u-pa50v {
  padding-top: 50px;
  padding-bottom: 50px;
}

.u-pr20 {
  padding-right: 20px;
}

.u-pr50 {
  padding-right: 50px;
}

.u-mw70 {
  max-width: 70%;

  @include bp(mobile-tablet) {
    max-width: 100%;
  }
}

// ============================================================================
//  Layout / Component
// ============================================================================

.hero-content-abs {
  width: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.card {
  position: relative;
  display: block;
}

.card--sm {
  height: 400px;
  max-height: 400px;

  @include bp(mobile) {
    height: auto;
  }

}

.card__img {
  height: inherit;
  max-height: 400px;

  @include bp(mobile) {
    height: 250px;
  }
}

.card__icon {
  position: absolute;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  top: 310px;
  left: 20px;
  z-index: 5;

  .card-thumb & {
    bottom: 20px;
  }
}

.card__desc {
  position: relative;
  max-width: 75%;
  padding: 20px 0 0;
  margin-bottom: 60px;
}

.card__desc-rel {
  max-width: 50%;
  padding-left: 50px;
  padding-top: 50px;
  padding-bottom: 40px;

  @include bp(mobile) {
    padding-top: 50px;
    padding-left: 0;
    padding-right: 0;
  }
}


.card-padded {
  padding: 20px 0;

  .home & {
    padding: 0;
  }

  .card__img {
    height: $card-height;
    position: relative;
  }

  .card__desc-rel {
    padding-left: 0;
  }

  .card__desc {
    padding-top: 20px;
  }

}

@include bp(mobile) {
  .card-padded {
    padding: 0;

    .card__img {
      height: 250px;
    }
  }

  .card__desc {
    padding: 20px 0 0;
    max-width: 100%;
  }

  .card__icon {
    top: 160px;
  }
}

.card__game .card__desc-rel {
  padding-left: 0;
  max-width: 70%;

  @include bp(mobile) {
    padding-top: 0;
    max-width: 100%;
  }
}

.article-cannery .card__desc-rel {
  padding-top: 0;
  padding-bottom: 0;

  @include bp(mobile) {
    padding-top: 20px;
    padding-left: 0;
    padding-right: 0;
  }
}

.type-process .card__desc-rel {
  padding-top: 20px;

  @include bp(mobile) {
    padding: 20px;
  }
}

.card__desc-l {
  max-width: 80%;

  @include bp(mobile-tablet) {
    max-width: 100%;
  }

  @include bp(mobile) {
    padding: 20px;
  }

}

.card__link-text {
  position: relative;
  left: auto;
  bottom: auto;

  &.card-link {
    position: relative;
    left: auto;
    bottom: auto;
    color: #fff;

    .post-type-archive-cannery &,
    .list-view & {
      color: $timeline-bg;
    }

    .type-process & {
      color: $nav-color;

      @include bp(mobile) {
        padding: 20px 20px 60px;
        margin: 0;
      }
    }

  }
}

// color

.bg-color {
  background-color: $bg-gray;

  .game-cannery & {
    background-color: $game-bg;
  }
}

.bg-color--pa {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #fff;
}


